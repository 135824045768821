header {
  display: grid;
  align-content: center;
  position: relative;
}

.header span {
  cursor: pointer;
}

.image-top {
  width: 100vw;
}

.hamburger-menu {
  width: 50px;
  margin-left: 30px;
  margin-top: 30px;
  position: absolute;
  top: 1%;
  left: 0px;
  z-index: 2;
}

.login-right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 0.7rem;
  width: 100px;

  margin-top: 30px;
  position: absolute;
  top: 1%;
  right: 100px;
  z-index: 2;
}

.login-right img {
  width: 3rem;
}
.login-right span {
  font-size: 3rem;
}

/* Target 700px wide */
@media (max-width: 700px) {
  .login-right {
    right: 50px;
  }
  .login-right span {
    font-size: 2rem;
  }
  .login-right img {
    width: 2rem;
  }
}
@media (max-width: 500px) {
  .login-right {
    right: 23px;
    gap: 5px;
  }
  .login-right span {
    font-size: 1.4rem;
  }
  .login-right img {
    width: 1.8rem;
  }
}

/* DROPDOWNMENU */
/* HAMBURGER MENU */
.dropdown-menu {
  display: block;
  position: absolute;
  width: 20%;
  top: 13%;
  left: 2%;
  z-index: 3;
  transition: 0.5s ease;
  font-size: 3rem;
}

.dropdown-menu a:hover {
  color: var(--pink-red);
}

/* BUTTONS STYLISH */
.buttons-container {
  position: absolute;
  z-index: 1;
  top: 48%;
  left: 17%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20%;
}

.buttons-container,
.button-artist,
.button-venue {
  width: 30vw;
}

/* Target 500px */
@media screen and (max-width: 500px) {
  .buttons-container.buttons-container--top {
    top: 50%;
    left: 6%;
    transform: translateY(-10px);
  }
  .buttons-container.buttons-container--top,
  .button-artist,
  .button-venue {
    width: 40vw;
  }
}

/* TEXTS WITH ASTERIcs */
.texts-under-button {
  width: 70vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10%;

  position: absolute;
  left: 15%;
  top: 80%;
}

.texts-under-button img {
  margin-left: 5px;
  margin-right: 5px;
  width: 1vw;
}

.right-side p {
  align-content: center;
}

.left-side p {
  text-align: right;
}

.right-side p {
  text-align: lrft;
}

.left-side div,
.right-side div {
  color: var(--grey);
  display: grid;
  grid-auto-flow: column;
  gap: 5px;
}

/* book now to rock FanFlex venues */
.book-to-rock {
  color: var(--grey);
  margin-top: 3vh;
  font-size: 2.2rem;

  display: grid;
  place-self: center;
}

.book-to-rock span {
  color: var(--pink-red);
  cursor: default;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1208px) {
  .dropdown-menu {
    top: 18%;
    font-size: 3rem;
  }
}

@media screen and (max-width: 995px) {
  .dropdown-menu {
    width: 30%;
    font-size: 3rem;
  }

  .book-to-rock {
    color: var(--grey);
    margin-top: 60px;
    font-size: 1.5rem;

    display: grid;
    place-self: center;
  }
}

@media screen and (max-width: 796px) {
  .App .hamburger-menu {
    width: 5vw;
    margin-top: 20px;
    margin-left: 20px;
  }

  .texts-under-button {
    font-size: 1.5vh;
    top: 78%;
  }

  .dropdown-menu {
    width: 35%;

    top: 15%;
    left: 2%;
  }
}

@media screen and (max-width: 678px) {
  .texts-under-button {
    font-size: 1.2vh;
    top: 78%;
  }

  .dropdown-menu {
    left: 2%;
  }
}

@media screen and (max-width: 556px) {
  .dropdown-menu {
    width: 45%;
    top: 18%;
    left: 2%;
  }

  .book-to-rock {
    font-size: 1.2rem;
  }

  .hamburger-menu-right {
    width: 70px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 437px) {
  .buttons-container {
    top: 55%;
  }

  .texts-under-button p {
    display: none;
    font-size: 1vh;
    top: 78%;
  }

  .texts-under-button img {
    width: 2vw;
  }

  .dropdown-menu {
    width: 60%;
    font-size: 2rem;
  }

  .book-to-rock {
    margin-top: 1px;
    margin-bottom: 5px;
  }

  .login-right {
    margin-right: 20px;
    margin-top: 20px;
    width: 80px;
  }
}
